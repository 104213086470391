import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Box, Flex, Heading, Text, Icon, Link } from '@chakra-ui/react'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
import Layout from '../components/Layout'

const About = ({data}) => {
  
  const aboutUs = {...data.wpPage, ...data.wpPage?.aboutUsAdditionalFields}
  const hasPeople = aboutUs.people?.length
  const hasMission = aboutUs.mission
  const hasQuote = aboutUs.quote?.text

  return (
    <Box 
      pt={[6, 12, 24]}
      px={[6, 12, 16]}>
      <Heading as="h1" size="2xl" mb="8">About Us</Heading>
      <Box  
        mb="8" 
        maxheight={['72px', 'unset']}
        dangerouslySetInnerHTML={{__html: aboutUs.content}} />

      { hasPeople && (
        <>
          <hr style={{border: '1px solid'}}/>
          <Text mt="8" fontWeight="bold">Meet the People</Text>
          <Flex mb="8" overflowX="auto" mr={[-6, 0]}>
            {aboutUs.people.map((people, idx) => (
              <Box key={idx} mr="10" mt="8" minWidth="200px">
                <GatsbyImage image={getImage(people.image?.localFile)} alt={people.name}/>
                <Text fontSize="xl" fontWeight="bold" mt="6">{people.name}</Text>
                <Text fontSize="xs">{people.designation}</Text>
                <Flex>
                  <Link href={people.socialMedia.facebookUrl} isExternal mr="4">
                    <Icon as={FaFacebookF} />
                  </Link>
                  <Link href={people.socialMedia.twitterUrl} isExternal>
                    <Icon as={FaTwitter} />
                  </Link>
                </Flex>
              </Box>
            ))}
          </Flex>
        </>
      )}

      {hasMission && (
        <Box backgroundColor="#feebcb" color="gray.900" py="20" px={[6, 12]} mx={[-6, -12, -16]}>
          <Text fontSize="sm" fontWeight="bold" textAlign={['left', 'center']}>our mission</Text>
          <Text fontSize={['3xl', '6xl']} fontWeight="bold">{aboutUs.mission}</Text>
        </Box>
      )}

      { hasQuote && (
        <Box py="20" px={[0, 10]}>
          <Box width={['80%', '30%']} borderTop="1px solid" mb="8" />
          <Text fontSize={['3xl', '5xl']} mb="8">❝ {aboutUs.quote?.text}</Text>
          <Flex 
            flexDirection={['column', 'row']}
            justifyContent={['', 'flex-end']} 
            alignItems="center">
            <Box textAlign={['center', 'right']} mr={[0, 8]} mb={[8, 0]}>
              <Text fontSize={['lg', '2xl']}>{aboutUs.quote.motto}</Text>
              <Text>{aboutUs.quote.quotemasterName}</Text>
            </Box>
            <GatsbyImage 
              alt={aboutUs.quote.quotemasterName}
              image={getImage(aboutUs.quote.quotemasterImage?.localFile)} 
              style={{borderRadius: '50%'}}/>
          </Flex>
        </Box>
      )}
    </Box>
  )
}

About.Layout = Layout
export default About

export const query = graphql`
  query AboutPageQuery {
    wpPage(slug: {eq: "about"}) {
      id
      title
      content
      aboutUsAdditionalFields {
        fieldGroupName
        mission
        people {
          designation
          name
          socialMedia {
            facebookUrl
            twitterUrl
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1, width: 200, quality: 100)
              }
            }
          }
        }
        quote {
          motto
          quotemasterName
          text
          quotemasterImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 100, height:100)
              }
            }
          }
        }
      }
    }
  } 
`